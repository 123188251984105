'use strict';

function AutoSubmit(di, defaultTimeout) {
    this.timeout = defaultTimeout;
    this.snippetManager = di.getService('snippetManager');
    this.fields = [];
    this.debugEnabled = false;

    var self = this;
    this.handles = [];
    var handle = this.snippetManager.on('after-update', function () {
        self.clearBindings();
        self.initAutoSubmit();
    });
    this.handles.push(handle);

    handle = this.snippetManager.on('before-update', function() {
        try {
            self.clearBindings();
        } catch (e) {
            console.error(e);
        }
    });
    this.handles.push(handle);
};

AutoSubmit.prototype.debug = function(message) {
    if (this.debugEnabled) {
        console.log(message);
    }
};

AutoSubmit.prototype.clearBindings = function() {
    $('*').off('.autosubmit');
    if (typeof this.fields.off !== 'undefined') {
        this.fields.off('.autosubmit');
        this.fields = [];
    }
};

AutoSubmit.prototype.initAutoSubmit = function() {
    this.clearBindings();

    var self = this;
    $('form').each(function () {
        self.fields = $(this).find('.autosubmit');
        if (self.fields.length > 0) {
            self.setupAutoSubmit(self.fields);
        }
    });
};

AutoSubmit.prototype.setupAutoSubmit = function(elems) {
    var handler = false;
    var self = this;

    elems.each(function () {
        var originalVal = $(this).val();
        if (self.isDelayedChange($(this))) {
            $(this).on('keyup.autosubmit', function(e) {
                if (originalVal === $(this).val())
                    return false;

                var timeout = self.timeout;
                if (typeof $(this).data('timeout') !== "undefined") {
                    timeout = $(this).data('timeout');
                }

                if (handler) {
                    clearTimeout(handler);
                }

                var keyCode = e.keyCode || e.which;
                if (keyCode !== 13) {
                    var element = this;
                    handler = setTimeout(function () {
                        self.submit(element);
                        originalVal = $(element).val();
                        self.debug('timeout submit keyup');
                    }, timeout);
                } else {
                    originalVal = $(this).val();
                }
            });
            $(this).on('blur.autosubmit', function() {
                var val = $(this).val();
                if (originalVal == val)
                    return false;

                if (handler) {
                    clearTimeout(handler);
                }

                self.debug('submit blur');
                self.submit(this);
                originalVal = val;
            })
        } else {
            $(this).on('change.autosubmit', function() {
                let elem = this;
                if (handler) {
                    clearTimeout(handler);
                }

                handler = setTimeout(function() {
                    self.debug('submit change timeout');
                    self.submit(elem);
                }, 250);
            });
        }
    });
};

AutoSubmit.prototype.submit = function(elem) {
    $(elem).closest('form').find('input[type="submit"]').click();
};

AutoSubmit.prototype.isDelayedChange = function(elem) {
    if (elem.tagName === 'textarea') {
        return true;
    }

    if (elem.attr('type') === 'text') {
        return true;
    }

    return false;
};

AutoSubmit.prototype.destroy = function() {
    this.clearBindings();
    this.handles.forEach(function () {
        this.unbind();
    });

    this.handles = [];
};
